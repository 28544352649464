<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-row :gutter="20" style="height: 100%">
              <el-col :span="16" class="l" style="height: 100%">
                <div class="guideBox">
                  <div class="tittleIndex2" style="padding-left: 0">
                    机构使用流程
                  </div>
                  <div
                    class="grid-content bg-purple"
                    style="
                      display: flex;
                      flex-wrap: wrap;
                      flex: 1;
                      justify-content: space-between;
                    "
                  >
                    <div class="grid-content bg-purple l_l bgImg1">
                      <div class="l_1">
                        <p class="p_1">第一步</p>
                        <p class="p_2">
                          课程资源<img
                            src="../../assets/JgIndex/triangle.png"
                            alt=""
                          />
                        </p>
                        <div class="stepBox">
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/myresourseList')"
                            >
                              我有课程<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                          <div class="flexExamples">
                            <p class="p_3 dh" @click="jump('/web/courseList')">
                              我没有课程<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid-content bg-purple l_l bgImg2">
                      <div class="l_1">
                        <p class="p_1">第二步</p>
                        <p class="p_2">
                          创建班级<img
                            src="../../assets/JgIndex/triangle.png"
                            alt=""
                          />
                        </p>
                        <div class="stepBox">
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/classListCtr')"
                            >
                              创建班级<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/classListCtr')"
                            >
                              选择课程<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/classListCtr')"
                            >
                              导入学员<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid-content bg-purple l_l bgImg3">
                      <div class="l_1">
                        <p class="p_1">第三步</p>
                        <p class="p_2">
                          开班申请<img
                            src="../../assets/JgIndex/triangle.png"
                            alt=""
                          />
                        </p>
                        <div class="stepBox">
                          <div class="flexExamples">
                            <p class="p_3 dh">
                              审核通过，通知学员学习<i
                                class="el-icon-arrow-right"
                              ></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                          <div class="flexExamples">
                            <p class="p_3 dh">
                              审核未通过，重新申请<i
                                class="el-icon-arrow-right"
                              ></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid-content bg-purple l_l bgImg4">
                      <div class="l_1">
                        <p class="p_1">第四步</p>
                        <p class="p_2">
                          学习管理<img
                            src="../../assets/JgIndex/triangle.png"
                            alt=""
                          />
                        </p>
                        <div class="stepBox">
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/classListCtr')"
                            >
                              绑定日报码<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/set/Openingstatistics')"
                            >
                              班级培训进度<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid-content bg-purple l_l bgImg5">
                      <div class="l_1">
                        <p class="p_1">第五步</p>
                        <p class="p_2">
                          培训结束<img
                            src="../../assets/JgIndex/triangle.png"
                            alt=""
                          />
                        </p>
                        <div class="stepBox">
                          <div class="flexExamples">
                            <p class="p_3 dh">
                              确认结算单<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                          <div class="flexExamples">
                            <p class="p_3 dh">
                              下载学员档案<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid-content bg-purple l_l bgImg6">
                      <div class="l_1">
                        <p class="p_1">第六步</p>
                        <p class="p_2">
                          信息统计<img
                            src="../../assets/JgIndex/triangle.png"
                            alt=""
                          />
                        </p>
                        <div class="stepBox">
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/set/Openingstatistics')"
                            >
                              机构开班情况<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                          <div class="flexExamples">
                            <p
                              class="p_3 dh"
                              @click="jump('/web/set/Studentstatistics')"
                            >
                              学员学习轨迹<i class="el-icon-arrow-right"></i>
                            </p>
                            <!-- <span style="margin-top: 5px">查看示例</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="8" class="r" style="height: 100%">
                <div class="noticeBox">
                  <div style="height: 45%">
                    <span class="tittleIndex2">公告</span>
                    <div>
                      <div
                        class="noticeContent"
                        v-for="(item, index) in tableData1"
                        :key="index"
                        @click="handleCurrentChange(item)"
                      >
                        <span>{{ item.noticeTitle }}</span>
                        <span
                          >{{ item.createTime | momentDate }}
                          <i class="el-icon-arrow-right"
                        /></span>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      height: 50%;
                      display: flex;
                      flex-direction: column;
                      position: relative;
                    "
                  >
                    <span class="tittleIndex2 tittleIndex3">资料下载</span>
                    <div ref="hei" style="flex: 1">
                      <el-table
                        :data="listData"
                        stripe
                        :show-header="false"
                        highlight-current-row
                        style="width: 100%; height: 100%; font-size: 12px"
                      >
                        <el-table-column
                          prop="fileName"
                          show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column
                          prop="createTime"
                          align="right"
                          label="操作"
                          width="100"
                        >
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="mini"
                              style="padding: 0 5px"
                              @click="lookExl(scope.row)"
                              >下载</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div style="margin-bottom: 10px">
                      <div class="pageNum">
                        <el-pagination
                          :total="fenYe.total"
                          :page-size="fenYe.size"
                          :current-page="fenYe.currentPage"
                          background
                          :page-sizes="[5, 10, 20, 30, 40]"
                          layout="total,prev, pager, next"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange1"
                        ></el-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
export default {
  name: "OtherHome2",
  components: {
    // Empty,
    // PageNum,
  },
  mixins: [List],
  data() {
    return {
      tableData1: [],
      getMenu: [],

      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 5,
        total: null, // 共多少页
      },
      listData: [],
    };
  },
  created() {
    this.getnew();
    this.getMenuData();
    this.getListData()
    this.getVideoList();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      menu: "getMenu",
    }),
  },
  methods: {
    more() {
      this.$router.push("/web/systemNotication");
    },
    getnew() {
      const parmas = {
        pageNum: "1",
        pageSize: "5",
      };
      this.$post("/notice/querySysNoticeList", parmas).then((res) => {
        this.tableData1 = res.data.list;
      });
    },
    //点击公告 进入对应的 公告详情页面
    handleCurrentChange(val) {
      const noticeId = val.noticeId;
      this.$router.push({
        path: "/web/noticationDetail",
        query: {
          noticeId,
        },
      });
    },
    getMenuData() {
      const getMenu = this.$toTree(
        this.menu,
        "id",
        "parentId",
        "children",
        "orderId"
      ); // 获取菜单tree
      this.getMenu = getMenu;
    },
    jump(url) {
      let temp = false;
      for (let i = 0; i < this.getMenu.length; i++) {
        console.log(this.getMenu[i].url);
        if (this.getMenu[i].url == url) {
          console.log(url);
          this.$router.push({
            path: url,
          });
        } else {
          temp = true;
        }
        if (this.getMenu[i].children.length) {
          for (let n = 0; n < this.getMenu[i].children.length; n++) {
            if (this.getMenu[i].children[n].url == url) {
              this.$router.push({
                path: url,
              });
            } else {
              temp = true;
            }
          }
        }
      }
      console.log(temp, url);
      if (temp && url == undefined) {
        //设立flag标志的条件
        this.$message.warning("没有该权限！");
      }
    },
    getListData(page = 1, pagesize = this.fenYe.size) {
      const params = {
        pageNum: page,
        pageSize: pagesize,
      };
      this.$post("/biz/roleFile/home/pageList", params)
        .then((res) => {
          this.listData = res.data.list;
          this.fenYe.total = res.data.total;
        })
        .catch(() => {
          return;
        });
    },
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange1(val) {
      this.fenYe.currentPage = val;
      console.log(this.fenYe.currentPage);
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    //下载
    lookExl(item) {
      this.$post('/biz/roleFile/download/record',{fileId:item.fileId}).then(ret => {
        if (ret.status == 0) {
             window.open(item.fileUrl);
        }
      })
    },
    getVideoList() {
      this.$post("biz/operationExample/homeList", {}).then((ret) => {
        console.log(ret);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.framePage-title {
  position: relative;
}
.framePage-title:after {
  position: absolute;
  background-color: #2d57f6;
  width: 4px;
  height: 20px;
  top: 25%;
  left: 0;
  content: "";
}
.tittleIndex2::after {
  position: absolute;
  background-color: #2d57f6;
  width: 4px;
  height: 20px;
  left: 0;
  content: "";
  top: 24px;
}
.framePage-body {
  background: #f7fbfe;
}
.tittleIndex2 {
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
}

.l {
  .guideBox {
    min-height: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(40, 120, 255, 0.1);
    padding: 0 30px;
    border-radius: 9px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .l_l {
    display: flex;
    height: 30%;
    border-radius: 12px;
    padding: 20px 25px;
    width: 48%;
    &:hover {
      box-shadow: 0px 0px 20px 0px rgba(40, 120, 255, 0.1);
    }
    .l_1 {
      // width: 60%;
      display: flex;
      flex-direction: column;
      .stepBox {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-around;
      }
      .flexExamples {
        display: flex;
        align-items: center;
        span {
          color:#F3C97A ;
          border-bottom: 1px solid #F3C97A;
          padding: 3px 0;
        }
      }
      .p_1 {
        color: #5393ff;
      }
      .p_2 {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 5px;
        font-weight: 600;
        position: relative;
        img {
          position: absolute;
          left: -28px;
          top: 8px;
        }
      }
      .dh:hover {
        color: #2878ff;
        cursor: pointer;
      }
      .p_3 {
        font-size: 15px;
        margin-top: 5px;
      }
    }
  }
  .bgImg1 {
    background-image: url("../../assets/JgIndex/JgIndex1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bgImg2 {
    background-image: url("../../assets/JgIndex/JgIndex2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bgImg3 {
    background-image: url("../../assets/JgIndex/JgIndex3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bgImg4 {
    background-image: url("../../assets/JgIndex/JgIndex4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bgImg5 {
    background-image: url("../../assets/JgIndex/JgIndex5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bgImg6 {
    background-image: url("../../assets/JgIndex/JgIndex6.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.r {
  .noticeBox {
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 38px 0px rgba(40, 120, 255, 0.1);
    border-radius: 9px;
    position: relative;
    .noticeContent {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        color: #2878ff;
        cursor: pointer;
      }
    }
  }
}
// .tittleIndex3::after {
//   position: absolute;
//   background-color: #2d57f6;
//   width: 4px;
//   height: 20px;
//   left: 0;
//   content: "";
//   top: 24px;
// }
.el-row {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  height: 100%;
  justify-content: space-around;
}
</style>
<style>
.tittleIndex3::after {
  position: absolute;
  background-color: #2d57f6;
  width: 4px;
  height: 20px;
  left: 0;
  content: "";
  top: 24px;
}
</style>
